// eslint-disable-next-line filenames/match-exported
import { defer, from, switchMap, of, throwError, type Observable } from 'rxjs';
import type { ApolloQueryResult, QueryOptions, OperationVariables, MaybeMasked } from '@apollo/client';
import apolloClient from 'services/graphql';
import language from 'services/i18n/language';

import type { QueryNames } from './graphql-query-names';

export interface NautilusQueryContext {
  // Unique name for the query. Needed for mocking multiple queries in tests.
  name: QueryNames;
  important?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers: Record<string, any>;
}

/**
 * GraphQL query as an Observable.
 * @description Additionally, if the query fails, it converts into a pipeline error
 * that can be caught.
 */
const graphQLQuery = <T = never, TVariables extends OperationVariables = OperationVariables>(
  query: QueryOptions<TVariables, T>['query'],
  options: Omit<QueryOptions<TVariables, T>, 'query'> & { context?: NautilusQueryContext } = {}
): Observable<ApolloQueryResult<MaybeMasked<T>>> => {
  const headers = {
    ...(options.context?.headers || {}),
    'Accept-Language': language.current,
  };
  const context = { ...options.context, headers };

  return defer(() => from(apolloClient.query<T>({ query, ...options, context }))).pipe(
    switchMap((response) => {
      if (response?.errors?.length) {
        return throwError(() => response.error);
      }

      return of(response);
    })
  );
};

export default graphQLQuery;
